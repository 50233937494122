import React from "react";
import "../styles/footer.css";
import logo from "../assets/images/edoceoLogo.png";
import quali from "../assets/images/qualiopi2024.png";
import pdfFile from "../assets/documents/Certificat_Qualiopi_FORMAONE.pdf"; 
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation("footer");

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth", // Enables smooth scrolling
    });
  };

  return (
    <footer className="footer">
      <div className="branding">
        <img src={logo} alt="Edoceo Logo" className="footer-logo" />
        <h1>
          <span>by </span>FormaOne
        </h1>
        <p className="footer-description">{t("description")}</p>
        <div className="social-icons">
          <i className="fab fa-tiktok"></i>
          <i className="fab fa-instagram"></i>
          <i className="fab fa-facebook-f"></i>
          <i className="fab fa-linkedin-in"></i>
        </div>
      </div>

      <div className="links">
        <ul>
          <Link
            to="/"
            onClick={handleScrollToTop}
            style={{
              listStyle: "none",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <li>{t("links.home")}</li>
          </Link>
          <Link
            to="/formation"
            onClick={handleScrollToTop}
            style={{
              listStyle: "none",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <li>{t("links.formations")}</li>
          </Link>
          <Link
            to="/about"
            onClick={handleScrollToTop}
            style={{
              listStyle: "none",
              textDecoration: "none",
              color: "inherit",
            }}
          >
            <li>{t("links.about_us")}</li>
          </Link>
        </ul>
        <button className="download-btn">
          <a href={pdfFile} download="Document.pdf">
            {t("links.download_pdf")}
          </a>
        </button>
      </div>

      <div className="footer-bottom">
        <p>{t("copyright")}</p>
        <img src={quali} alt="Qualiopi logo" className="qualiopi-logo" />
      </div>
    </footer>
  );
}

export default Footer;
