import React from "react";
import '../styles/collaborators.css'
import adTrain from '../assets/images/adTrain.svg'
import lvmh from '../assets/icons/adcertif.png'
import elec from '../assets/images/elec.png'
import cambridge from '../assets/icons/in3m.png'
import logo from '../assets/icons/ATA.webp'
const logos = [
  {
    id: 1,
    url: logo,
    alt: "Total energies",
  },
  {
    id: 2,
    url: lvmh,
    alt: "LVMH",
  },
  // {
  //   id: 5,
  //   url: adTrain,
  //   alt: "adTrain",
  // },
  {
    id: 6,
    url: elec,
    alt: "elec",
  },
 
 
];

const LogoCarousel = () => {
  return (
    <div className="max-width">
      <div className="carousel-container">
        <div className="carousel-content">
          <h1 className="carousel-title">
            <span className="highlight">Nos</span> organismes partenaires
          </h1>
          <p className="carousel-subtitle">
            et + de 100 stagiares font confiance à <span>Edoceo</span>
          </p>

          <div className="logo-track-container">
            <div className="logo-track">
              {[...logos, ...logos].map((logo, index) => (
                <div key={`${logo.id}-${index}`} className="logo-item">
                  <img src={logo.url} alt={logo.alt} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoCarousel;
