import React, { useState } from "react";
import "../styles/contact.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Footer from "./Footer";
import Navbar from "./Navbar";
import emailIcon from "../assets/icons/mail.png";
import locationIcon from "../assets/icons/location.png";
import telIcon from "../assets/icons/telephonique.png";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t } = useTranslation("contact");
  const [training, setTraining] = useState("");

  const handleChange = (event) => {
    setTraining(event.target.value);
  };

  return (
    <div className="contact-wrapper">
      <Navbar />
      <div className="contact-container">
        {/* Header */}
        <div className="contact-header">
          <h1>{t("header")}</h1>
        </div>

        <div className="contact-content">
          {/* Left Section */}
          <div className="contact-left-section">
            <h2>{t("leftSection.title")}</h2>
            <p>{t("leftSection.description")}</p>
            <div className="contact-info">
              <div className="contact-item">
                <img src={emailIcon} alt="email" />
                <a
                   href="mailto:contact@edoceo.pro"
                  style={{ textDecoration: "none", listStyle: "none" }}
                >
                 {t("leftSection.email")}
                </a>
              </div>
              <div className="contact-item">
                <img src={locationIcon} alt="location" />
                <p>{t("leftSection.location")}</p> 
              </div>
              <div className="contact-item">
                <img src={telIcon} alt="location" />
                <p>{t("leftSection.telephone")}</p> 
              </div>
            </div>
          </div>

          {/* Right Section */}
          <div className="contact-right-section">
            <h2>{t("rightSection.title")}</h2>
            <form className="contact-form">
              <div className="input-group">
                <TextField
                  fullWidth
                  label={t("rightSection.fields.fullName")}
                  id="fullWidth"
                />
                <TextField
                  fullWidth
                  label={t("rightSection.fields.phone")}
                  id="fullWidth"
                />
              </div>
              <div className="input-group">
                <TextField
                  fullWidth
                  label={t("rightSection.fields.email")}
                  id="fullWidth"
                />
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t("rightSection.fields.training")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={training}
                      label={t("rightSection.fields.training")}
                      onChange={handleChange}
                    >
                      <MenuItem value={10}>{t("rightSection.trainingOptions.cfa")}</MenuItem>
                      <MenuItem value={20}>{t("rightSection.trainingOptions.af")}</MenuItem>
                      <MenuItem value={30}>{t("rightSection.trainingOptions.qualifying")}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </div>
              <div className="input-group">
                <TextField
                  id="outlined-multiline-static"
                  label={t("rightSection.fields.message")}
                  multiline
                  rows={4}
                  fullWidth
                />
              </div>
              <button className="snd-btn">{t("rightSection.submit")}</button>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;